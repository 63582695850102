import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useHistory } from "react-router-dom";



import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';



import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';




import {currentRouteSet} from '../../actions/actions';

import {pathSpliter,checkAuthBranchWare,dateTimeFormat,currentDateTime,dateFormat} from '../../lib/functions'
import moment from 'moment';


import _ from 'lodash';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
    const history = useHistory();

  useEffect(()=>{
    getSupplierReceipts();
    currentRouteSet(pathSpliter(location.pathname,1));
    is_voucher_receipt_set(authInfo.is_voucher_receipt)

    getPayCode();
    getDueEmis()
  },[])

    // Init states start
 
    
    let [rcv_id,rcv_id_set] = useState(0)
    let [receipts,receiptsSet] = useState([])
    let [rcvCart,rcvCartSet] = useState([])
    let [loadIntoAccounts,loadIntoAccountsSet] = useState([])
    let [loadFromAccounts,loadFromAccountsSet] = useState([])
    let [dueEmis,dueEmisSet] = useState([])
    let [selectedDueEmi,selectedDueEmiSet] = useState(null)
    let [is_voucher_receipt,is_voucher_receipt_set] = useState('')

    
    
    let [directIncomeAccounts,directIncomeAccountsSet] = useState([])
    let [currentLaibilityAccounts,currentLaibilityAccountsSet] = useState([])
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)

    let [action,action_set] = useState('create');
    let [rcv_code,rcv_code_set] = useState('');
    let [rcv_ind_id,rcv_ind_id_set] = useState(null);

    let [rcv_amount,rcv_amount_set] = useState('');
    let [rcv_total,rcv_total_set] = useState(0);
    let [discount_amount,discount_amount_set] = useState(0);
    let [discount_amount_per,discount_amount_per_set] = useState(0);
    let [tax_amount,tax_amount_set] = useState(0);
    let [tax_amount_per,tax_amount_per_set] = useState(0);

    let [narration,narration_set] = useState('');
    let [dueVouchers,dueVouchersSet] = useState([]);
    let [selectedDueVoucher,selectedDueVoucherSet] = useState(null);
    let [selectedBaseUnit,selectedBaseUnitSet] = useState(null);
    let [is_smg,is_smg_set] = useState('yes');

    

    let [selectedDirectIncomeAcc,selectedDirectIncomeAccSet] = useState(null);
    let [selectedCurrentLibilityAcc,selectedCurrentLibilityAccSet] = useState(null);
    
    
    const [rcvDate, rcvDateSet] = useState(currentDateTime);
    const [selectedAccountInTo, selectedAccountInToSet] = useState(null);
    const [selectedAccountFrom, selectedAccountFromSet] = useState(null);

    let to_acc_ref = useRef(null)
    let from_acc_ref = useRef(null)
    let deu_voucher_ref = useRef(null)
    let rcv_amount_ref = useRef(null)
    let discount_amount_ref = useRef(null)
    let tax_amount_ref = useRef(null)
    let rcv_code_ref = useRef(null)
    let creation_date_ref = useRef(null)
    let narration_ref = useRef(null)

    let [is_discount,is_discount_set] =  useState('no');
    let [is_tax,is_tax_set] =  useState('no');


    useEffect(()=>{
      if(is_voucher_receipt == 'yes' && selectedAccountFrom != null){
        getDueVouchers()
      
      }
       dueEmisSet([])
        getDueEmis()
    },[selectedAccountFrom])


  let getDueEmis = async ()=>{
    if(selectedAccountFrom != null){
        await axios.post(`${API_URL}/api/get-due-emis`,{customerId: selectedAccountFrom != null ? selectedAccountFrom.acc_id : 0},{headers:{'auth-token':authInfo.token}}).then(res=>{
        dueEmisSet(res.data)
        });
    }
      
    }

  
    let getDueVouchers = async ()=>{
      await axios.post(`${API_URL}/api/get-due-sales-vouchers`,{customerId: selectedAccountFrom != null ? selectedAccountFrom.acc_id : 0},{headers:{'auth-token':authInfo.token}}).then(res=>{
        dueVouchersSet(res.data)
        });
    }

    

  
  useEffect(()=>{
    getSupplierReceipts()
  },[rcvDate])


  useEffect(()=>{
    if( directIncomeAccounts.length == 0){
       axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_type_id:'direct_expense'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        directIncomeAccountsSet(res.data)
        selectedDirectIncomeAccSet(res.data.length != 0 ? res.data[0]:null)

      })
    }
  },[is_discount])

  useEffect(()=>{
    if(is_tax == 'yes' && currentLaibilityAccounts.length == 0){
       axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_type_id:'current_liability'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        currentLaibilityAccountsSet(res.data)
      })
    }
  },[is_tax])

   
    const rcvDateChange = (date) => {
        rcvDateSet(date);
      };


    
    const getSupplierReceipts = async ()=>{
        await axios.post(`${API_URL}/api/get-debitor-rcv-with-details`,{oneDate:rcvDate},{headers:{'auth-token':authInfo.token}}).then(res=>{
        receiptsSet(res.data)
      })
    }


    
    const [account_name_from, account_name_from_set] = useState("");
    const [account_name_to, account_name_to_set] = useState("");

    const [to_balance, to_balance_set] = useState(0);
    const [from_balance, from_balance_set] = useState(0);

    useEffect(()=>{
      getLoadFromAccounts()
    },[account_name_from])

 useEffect(()=>{
      getLoadIntoAccounts()
    },[account_name_to])

    const getLoadIntoAccounts = async() => {
        await axios.post(`${API_URL}/api/get-accounts-by-search`,{query:account_name_to,search:true,multiType:[
          'cash_in_hand','bank_account'
        ]},{headers:{'auth-token':authInfo.token}}).then(res=>{
          loadIntoAccountsSet(res.data)
        })
    };

    const getLoadFromAccounts = async() => {
      await axios.post(`${API_URL}/api/get-accounts-by-search`,{query:account_name_from,search:true,multiType:[
        'debitor'
      ]},{headers:{'auth-token':authInfo.token}}).then(res=>{
        loadFromAccountsSet(res.data)
      })
  };



  useEffect(()=>{
    if(selectedAccountFrom != null){
       axios.post(`${API_URL}/api/get-sundry-debitor-balance`,{
        customerId : selectedAccountFrom.acc_id
      },{headers:{'auth-token':authInfo.token}}).then(res=>{
        from_balance_set(res.data.total_balance)
      })
    }
  },[selectedAccountFrom])

  useEffect(()=>{
    if(selectedAccountInTo != null){
       axios.post(`${API_URL}/api/get-accounts-balance`,{
        accId : selectedAccountInTo.acc_id,
        accType : "'cash_in_hand'"
      },{headers:{'auth-token':authInfo.token}}).then(res=>{
        to_balance_set(res.data.total_balance)
      })
    }
  },[selectedAccountInTo])

    const getPayCode = async() => {
      return  await axios.post(`${API_URL}/api/get-debitor-receipt-code`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          rcv_code_set(res.data)
        })
    };


    useEffect(()=>{
      let total = (rcv_amount)  - ( parseFloat(tax_amount))
      rcv_total_set(total)
    },[rcv_amount,discount_amount,tax_amount])
  
  



    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{

            let totalDiscountAmount = _.sumBy(rcvCart,(tran)=>parseFloat(tran.discount_amount))
            let totalTexAmount = _.sumBy(rcvCart,(tran)=>parseFloat(tran.tax_amount))
            let totalPayTotalAmount = _.sumBy(rcvCart,(tran)=>parseFloat(tran.rcv_total))


            if(selectedAccountFrom == null && action !=  'update' && is_smg == 'yes'){
              swal({
                title:`Select Customer`,
                icon:'warning'
              });
              return false
            }

           else if(rcvCart.length == 0){
              swal({
                title:`Receipt Cart is Empty.`,
                icon:'warning'
              });
              return false
            }else{
            let rcvLoad = {
              rcv:{
                rcv_code:rcv_code.trim(),
                creation_date:rcvDate,
                discount_total:totalDiscountAmount,
                tax_total:totalTexAmount,
                rcv_total : totalPayTotalAmount,
                narration,
                rcv_id,
                action
              },
              rcvDetail:rcvCart
            }
            loadingSaveSet(true);
            await axios.post(`${API_URL}/api/save-debitor-receipt`,{  
              ...rcvLoad
            },{headers:{'auth-token': authInfo.token}}).then(async res=>{

              if( is_smg == 'yes' &&  selectedAccountFrom.contact_no.trim() != ''){
            

                await axios.post(`https://sms.solutionsclan.com/api/sms/send`,
                  {
                      "apiKey" : "A000475c020ead4-57d6-4e2b-be87-db85fbb8856d",
                      "contactNumbers" :  selectedAccountFrom.contact_no.replace('-',''),
                      "senderId" : "8809612440640",
                      "textBody" : `Dear ${selectedAccountFrom.acc_name}, Your payment amount is ${format(parseFloat(totalPayTotalAmount).toFixed(2),2)}, Your Previous Due is ${format(parseFloat(from_balance).toFixed(2),2)} & Current Due is ${format(parseFloat(from_balance - parseFloat(totalPayTotalAmount)).toFixed(2),2)},   লাল মোহাম্মদ ট্রেডার্স ও সাব্বির ইলেকট্রনিক . Cell : 01740-636503 `
                  },
    
                  {headers:{
                    contentType: 'application/json; charset=utf-8',
                    'auth-token':authInfo.token
                  }}
                
                ).then(res=>{
                  console.log(res.data)
                  loadingSaveSet(false);

                })
                
              }


              loadingSaveSet(false);
             if(res.data.error == false){
              swal({title:`${res.data.msg}. Do you want to view invoice?`,icon:'success',buttons:true}).then(confirm=>{
                if(confirm){
                  history.push(`/accounts/quick-debitor-receipt/${res.data.id}`)
                 
                }else{
                  window.location.reload()
                }
              })
               rcvDateSet(currentDateTime)
               rcvCartSet([])
               action_set('create')
               narration_set('')
               rcv_id_set(0)
               getPayCode()
               getSupplierReceipts()
             }else{
              swal({
                title: res.data.msg,
                icon:'warning'
              })
             }
            });

            }
         
            
          
          
    }


  const removeJournalTran = (sl)=>{
    let preCopy =  [...rcvCart];
    preCopy.splice(sl, 1);
    rcvCartSet(preCopy)

  }

  const addTOJournal = ()=>{
    

    if(selectedAccountInTo == null &&  discount_amount == 0 ){
      swal({
        title:`Select a  To  Account/ Debtor`,
        icon:'warning'
      });
      return false
    }else if(selectedAccountFrom == null){
        swal({
          title:`Select a From Account`,
          icon:'warning'
        });
        return false;
      }else if(selectedDueEmi == null && dueEmis.length > 0){
        swal({
          title:`Select a EMI`,
          icon:'warning'
        });
        return false;
      }else if( ( discount_amount > 0  )&&  selectedDirectIncomeAcc == null ){
        swal({
          title:`Select a Discount / Direct Income Account`,
          icon:'warning'
        });
        return false
      }else if( ( tax_amount > 0 )&&  selectedCurrentLibilityAcc == null ){
        swal({
          title:`Select a Tax / Current Laibility Account`,
          icon:'warning'
        });
        return false;
      }else if(rcv_amount < 0){
      swal({
        title:`Received Amount Should be Valid `,
        icon:'warning'
      });
      return false
    }else if(is_voucher_receipt == 'yes' && selectedDueVoucher == null){
      swal({
        title:`Select Due Voucher `,
        icon:'warning'
      });
      return false
    }else{

     let check =  rcvCart.findIndex((tran)=>{
        return tran.from_acc_id == selectedAccountFrom.acc_id
      })

      if(check == -1 && rcvCart.length != 0){
        swal({
          title:`One Receipt for  should be one Debtor's Transaction`,
          icon:'warning'
        });
        return false
      }

      let tran = {
        into_acc_id : selectedAccountInTo != null ? selectedAccountInTo.acc_id : 0,
        from_acc_id : selectedAccountFrom.acc_id,
        into_acc_name : selectedAccountInTo != null ? selectedAccountInTo.acc_name : '',
        from_acc_name : selectedAccountFrom.acc_name,
        emi_id        : selectedDueEmi != null ? selectedDueEmi.emi_id : 0,
        emi_no        : selectedDueEmi != null ? selectedDueEmi.emi_no : '',
        amount        : selectedDueEmi != null ? selectedDueEmi.amount : '',
        voucher_no    : selectedDueVoucher != null ? selectedDueVoucher.voucher_no : '',
        direct_income_id    : selectedDirectIncomeAcc != null ? selectedDirectIncomeAcc.acc_id : 0,
        current_liability_id    : selectedCurrentLibilityAcc != null ? selectedCurrentLibilityAcc.acc_id : 0,
        rcv_amount : rcv_amount =='' ? 0 :rcv_amount,
        discount_amount : discount_amount =='' ? 0 :discount_amount,
        tax_amount : tax_amount =='' ? 0 : tax_amount,
        rcv_total : rcv_total =='' ? 0 : rcv_total,
      }
      if(rcv_ind_id == null){
        rcvCartSet([...rcvCart,tran])
      }else{
        let preCopy =  [...rcvCart];
        preCopy[rcv_ind_id] = tran
        rcvCartSet(preCopy)

      }


      rcv_ind_id_set(null)
      selectedAccountInToSet(null)
      // selectedAccountFromSet(null)
      rcv_amount_set('')
      discount_amount_set(0)
      discount_amount_per_set(0)
      tax_amount_set(0)
      tax_amount_per_set(0)

      selectedDueEmiSet(null)

      
        is_discount_set('no')
      
        is_tax_set('no')

        selectedDueVoucherSet(null)
        selectedDirectIncomeAccSet(null)
        selectedCurrentLibilityAccSet(null)
      
      
     }

    
     

   
    }

    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
    authInfo.role !='user'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>rcvEdit(props.rowData)}/>
        <Link to={`/accounts/quick-debitor-receipt/${props.rowData[0]}`}><ReceiptIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(17, 95, 95)'}}/></Link>
        <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>rcvDelete(props.rowData)}/>
            
   </>):''
 }
      
      </div>)
    
    }


    const rcvEdit = (row,index)=>{
      let rcvment =  receipts.filter((rcv)=>rcv.rcv_id == row[0])
      rcvment = rcvment[0]
      rcvCartSet(rcvment.details)
      
      rcv_id_set(rcvment.rcv_id)
      action_set('update')
      rcv_code_set(rcvment.rcv_code)
      rcvDateSet(rcvment.creation_date)
      narration_set(rcvment.narration)
     
    }


    const editJournalTran = (row,sl)=>{
      rcv_ind_id_set(sl)
      selectedAccountInToSet({acc_name:row.into_acc_name,acc_id:row.into_acc_id})
      selectedAccountFromSet({acc_name:row.from_acc_name,acc_id:row.from_acc_id})

      selectedDueEmiSet({emi_id:row.emi_id,emi_no:row.emi_no})

      selectedDirectIncomeAccSet({acc_name:row.direct_income_acc_name,acc_id:row.direct_income_id})
      selectedCurrentLibilityAccSet({acc_name:row.current_liability_acc_name,acc_id:row.current_liability_id})

      selectedDueVoucherSet({voucher_no:row.voucher_no})
      rcv_amount_set(row.rcv_amount)
      discount_amount_set(row.discount_amount)
      tax_amount_set(row.tax_amount)

      if(row.voucher_no != ''){
        is_voucher_receipt_set('yes')
      }
      if(row.discount_amount != 0){
        is_discount_set('yes')
      }
      if(row.tax_amount != 0){
        is_tax_set('yes')
      }
    }


    const rcvDelete = async (row)=>{
      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{
        if(yes){
          let Receipt =  receipts.filter((rcv)=>rcv.rcv_id == row[0])
          await axios.post(`${API_URL}/api/delete-debitor-receipt`,{rcv_id:Receipt[0].rcv_id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{


                  getSupplierReceipts();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })
                  
                })
        }else{
          return false
        }
      })
      

          }
   
    const columns = [
      {name: "rcv_id",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "rcv_code",label: "Receipt Code",options: {filter: true,sort: true}},
      {name: "creation_date",label: "Receipt Date",options: {filter: true,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(<p>{ moment(tableMeta.rowData[3]).format(dateFormat) }</p>) 
        }
      }},
      // {name: "tax_total",label: "Total Tax",options: {filter: true,sort: true}},
      {name: "acc_name",label: "Debtor/ Customer",options: {filter: true,sort: true}},
      {name: "discount_total",label: "Total Discount",options: {filter: true,sort: true}},

      {name: "rcv_total",label: "Received Total",options: {filter: true,sort: true}},
      {name: "narration",label: "Narration",options: {filter: true,sort: true}},
      {name: "user_name",label: "Created By",options: {filter: true,sort: true}},


      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }


    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Receipt From Debtor/ Customer    </h2>
      <Grid container spacing={2}>
      <Grid  xs={12} sm={4}>

        
<Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
<Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={loadFromAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              
              getOptionLabel={(option) => option.display_text}
              value={selectedAccountFrom}
              onChange={(e,selectedObj)=>{
                selectedAccountFromSet(selectedObj)
                if (e.key == "Enter") {
                  to_acc_ref.current.focus()
               }
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={from_acc_ref}
                  onChange={(e)=>{
                    account_name_from_set(e.target.value);
                   
                  }}
                  {...params}
                  label="Received From Debtor/ Customer"
                  variant="outlined"
                
                />
              )}
          />

{
  selectedAccountFrom != null ?(
<p className="balance-availity">Receivable  : {parseFloat(from_balance).toFixed(2)}</p>
  ):''
}

</Grid>



<Grid item xs={12} sm={12} style={{marginBottom:'10px'}}>
        {
  is_voucher_receipt == 'yes'?(<>
    <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={dueVouchers} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.display_text}
              value={selectedDueVoucher}
              
              onChange={(event,selectedObj)=>{
                  selectedDueVoucherSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                inputRef={deu_voucher_ref}
                onKeyDown={event => {
                    if (event.key == "Enter") {
                     rcv_amount_ref.current.focus()
                    }
                  }}

                  {...params}
                  label="Choose a Due Voucher"
                  variant="outlined"
                
                />
              )}
          />
           <b style={{margin:'0px',padding:'0px',float:'right',color:'red'}}>  ,  Due : {selectedDueVoucher != null ?parseFloat( selectedDueVoucher.due).toFixed(2):'0'} </b>
           <b style={{margin:'0px',padding:'0px',float:'right',color:'green'}}> Paid : {selectedDueVoucher != null ? parseFloat( selectedDueVoucher.paid).toFixed(2):'0'} </b>
           <b style={{margin:'0px',padding:'0px',float:'right',color:'black'}}> => </b>
           <b style={{margin:'0px',padding:'0px',float:'right',color:'black'}}> Voucher  </b>

  
  </>):''

}
        </Grid>



{
  dueEmis.length > 0 ? (<>
<Grid item xs={12} sm={12} style={{marginBottom:'10px'}} >
     
     <Autocomplete
               autoHighlight={true}
               openOnFocus={true}
               style={{ width: '100%' }}
               options={dueEmis} 
               size="small"
               classes={{
                 option: classes.option,
               }}
               getOptionLabel={(option) => option.emi_no}
               value={selectedDueEmi}
               
               onChange={(event,selectedObj)=>{
                   selectedDueEmiSet(selectedObj)
               }}
               renderInput={(params) => (
                 <TextField
            
                   {...params}
                   label="Choose a EMI"
                   variant="outlined"
                 
                 />
               )}
           />
 
 
         </Grid>
         <b style={{margin:'0px',padding:'0px',float:'right',color:'red'}}>EMI Due : {selectedDueEmi != null ? selectedDueEmi.due:'0'}</b>
  </>):(<></>)
}




      <Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
      <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={loadIntoAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              
              getOptionLabel={(option) => option.acc_name}
              value={selectedAccountInTo}
              onChange={(e,selectedObj)=>{
                selectedAccountInToSet(selectedObj)
                if (e.key == "Enter") {
                  rcv_amount_ref.current.focus();
                }
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={to_acc_ref}
                  onChange={(e)=>{
                    account_name_to_set(e.target.value);
                   
                  }}
                  {...params}
                  label="Received Into Account"
                  variant="outlined"
                
                />
              )}
          />
          {
            selectedAccountInTo != null ?(
              <p className="balance-availity">Balance  :  {parseFloat(to_balance).toFixed(2)}</p>

            ):''
          }

      </Grid>
     





     











<Grid item xs={12} sm={12}> 

<TextField type="number"  
          autoComplete='off' className={classes.fullWidth} 
        label="Received Amount" name="rcv_amount" value={rcv_amount} 

        inputRef={rcv_amount_ref}
        onKeyDown={event => {
            if (event.key == "Enter") {
                addTOJournal()
                from_acc_ref.current.focus()
            }
        }}

        variant="outlined" size="small" onChange={(e)=>{
          
                 rcv_amount_set(e.target.value)
           
            }} />


        
        </Grid>


        <Grid item xs={12} sm={12} style={{textAlign:'left',margin:'10px',color: '#222',
    fontWeight: 'bold',display:''}}>
              <input type="checkbox" 
               id="is_discount" checked={is_discount=='yes'?true:false} name="is_discount" 
                onChange={(e)=>is_discount_set(e.target.checked?'yes':'no')}
              />
              <label for="is_discount">Any Discount ?</label>
        </Grid>

       
            {
              is_discount == 'yes'?(<>
                    <Grid  item xs={12} sm={12} style={{marginBottom: '17px',width:'100%',display:''}}>
        <Grid  item xs={12} sm={6} style={{float:'left'}}>
            <TextField type="number"  style={{width:'100%'}}
                    
                    autoComplete='off' className={classes.fullWidth} 
                    label="Discount Amount" name="discount_amount" value={discount_amount} 
                    variant="outlined" size="small" onChange={(e)=>{

                      let discountPercent = ((100 * e.target.value)/rcv_amount)
                      discount_amount_per_set(discountPercent)

                     discount_amount_set(e.target.value)

                    
                            
                    
                        }} />
            </Grid>

           

            <Grid item xs={12} sm={5}  style={{float:'right',display:'none'}}>
            <TextField type="number"  
           
                     autoComplete='off' className={classes.fullWidth} 
                    label="Discount %" name="discount_amount_per" value={discount_amount_per} 
                    variant="outlined" size="small" onChange={(e)=>{

                      let discountAmount = (rcv_amount*e.target.value)/100;
                      discount_amount_set(discountAmount) 
                      discount_amount_per_set(e.target.value)

                    
                            
                    
                        }} />
                         
                         <div style={{marginBottom:'10px'}}></div>
                         
            </Grid>
        </Grid>
       
       
      


        <Grid item xs={12} sm={12} style={{marginBottom:'10px',display:'none'}}>
        <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={directIncomeAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedDirectIncomeAcc}
              
              onChange={(event,selectedObj)=>{
                selectedDirectIncomeAccSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
              

                  {...params}
                  label="Choose a Discount Account"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>
        </>):''
            }

          
        <Grid item xs={12} sm={12} style={{textAlign:'left',margin:'10px',color: '#222',
    fontWeight: 'bold',display:'none'}}>
              <input type="checkbox" 
               id="is_tax" checked={is_tax=='yes'?true:false} name="is_tax" 
                onChange={(e)=>is_tax_set(e.target.checked?'yes':'no')}
              />
              <label for="is_tax">Any Tax Deducted?</label>
        </Grid>



        {
          is_tax == 'yes'?(<>
              <Grid item xs={12} sm={12} style={{marginBottom: '7px',display:'none'}}>
        <Grid item xs={12} sm={6} style={{float:'left'}}>
            <TextField type="number"  
             inputRef={tax_amount_ref}
             onKeyDown={event => {
                 if (event.key === "Enter") {
                     rcv_code_ref.current.focus()
                 }
             }}
                     autoComplete='off' className={classes.fullWidth} 
                    label="Tax Deducted " name="tax_amount" value={tax_amount} 
                    variant="outlined" size="small" onChange={(e)=>{

                      let texPercent = ((100 * e.target.value)/rcv_amount)
                      tax_amount_per_set(texPercent)

                     tax_amount_set(e.target.value)
                    
                            
                    
                        }} />
            </Grid>

            <Grid item xs={12} sm={6} style={{float:'right'}}>
            <TextField type="number"  
                    autoComplete='off' className={classes.fullWidth} 
                    label="Tax Deducted %" name="tax_amount_per" value={tax_amount_per} 
                    variant="outlined" size="small" onChange={(e)=>{

                      let texPercent = ((100 * e.target.value)/rcv_amount)
                      tax_amount_per_set(texPercent)

                     tax_amount_set(e.target.value)
                    
                            
                    
                        }} />
                         <div style={{marginBottom:'10px'}}></div>
            </Grid>

           
        </Grid>
       
         
       


        <Grid item xs={12} sm={12} style={{marginBottom: '7px',display:'none'}}>
        <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={currentLaibilityAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedCurrentLibilityAcc}
              
              onChange={(event,selectedObj)=>{
                selectedCurrentLibilityAccSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
              

                  {...params}
                  label="Choose a Tax Account"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>
          </>):''
        }

        


        <Grid item xs={12} sm={12} style={{display:'none'}}> 

<TextField type="number"  
          autoComplete='off' className={classes.fullWidth} 
        label="Received Total" name="rcv_total" value={rcv_total} 
                disabled
        // inputRef={rcv_total_f}
        // onKeyDown={event => {
        //     if (event.key === "Enter") {
        //         discount_amount_ref.current.focus()
        //     }
        // }}

        variant="outlined" size="small" onChange={(e)=>{
          
                 rcv_total_set(e.target.value)
                
           
            }} />


        
        </Grid>



      

        
        <span>Press Enter Key to Receipt Cart </span>


        <Grid item xs={12} sm={6} style={{marginTop: '20px'}}>
           <Button onClick={()=>addTOJournal()} style={{background: 'rgb(109 113 113)',color: 'white',fontSize: '10px'}}>Add To Receipt</Button>
        </Grid>


      </Grid>

      <Grid item xs={12} sm={8} style={{paddingTop: '0px'}}>

        <Grid item xs={12} sm={12}>
        <Grid item xs={12} sm={4} style={{float:'left'}}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
         inputRef={rcv_code_ref}
         onKeyDown={event => {
             if (event.key === "Enter") {
                 creation_date_ref.current.focus()
             }
         }}
        label="Receipt Code" name="rcv_code" value={rcv_code} 
        variant="outlined" size="small" onChange={(e)=>rcv_code_set(e.target.value)} />
        </Grid>

        <Grid item xs={1} sm={2}>
          <div></div>
        </Grid>


        <Grid item xs={12} sm={4} style={{float:'right',marginTop: '-25px',marginLeft:'5px'}}> 



        <LocalizationProvider dateAdapter={AdapterDateFns} style={{marginTop: '8px'}}>
        <Stack spacing={3}>
          <DesktopDatePicker
           readOnly={authInfo.role == 'user' ? true : false}
            label="Receipt Date" 
            inputFormat={dateTimeFormat}
            inputRef={creation_date_ref}
           
            value={rcvDate}
            onChange={(e)=>rcvDateChange(e)}
            renderInput={(params) => <TextField 
              onKeyDown={event => {
                if (event.key === "Enter") {
                    narration_ref.current.focus()
                }
            }}
            
              {...params} />}
          />
          
        </Stack>
        </LocalizationProvider>



       
        </Grid>
        </Grid>
     
     




        <Grid item xs={12} sm={12}>
        <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
              <TableRow>
              <TableCell align="left"   style={{width:'1%'}}>SL</TableCell>
              <TableCell align="left"   style={{width:'20%'}}>Received Into </TableCell>
              <TableCell align="left"   style={{width:'20%'}}>From Customer </TableCell>
              <TableCell align="left"   style={{width:'20%'}}>EMI No</TableCell>
              <TableCell align="left"   style={{width:'20%'}}> Voucher</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Amount</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Discount</TableCell>
              {/* <TableCell align="right"  style={{width:'10%'}}>Tax</TableCell> */}
              {/* <TableCell align="right"  style={{width:'10%'}}>  Amount</TableCell> */}
              <TableCell align="right"  style={{width:'10%'}}>Actions</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
              {
                rcvCart.map((acc,sl)=>(<>
                   <TableRow key={sl} style={{background: sl == rcv_ind_id ? '#dedede':'',  transition: '1s'}}>
                  <TableCell align="left">{sl+parseFloat(1)}</TableCell>
                  <TableCell align="left">{acc.into_acc_name}</TableCell>
                  <TableCell align="left">{acc.from_acc_name}</TableCell>
                  <TableCell align="left">{acc.emi_no}</TableCell>
                  <TableCell align="left">{acc.voucher_no}</TableCell>
                  <TableCell align="right">{format(parseFloat(acc.rcv_amount).toFixed(2))}</TableCell>
                  <TableCell align="right">{format(parseFloat(acc.discount_amount).toFixed(2))}</TableCell>
                  {/* <TableCell align="right">{format(parseFloat(acc.tax_amount).toFixed(2))}</TableCell> */}
                  {/* <TableCell align="right">{format(parseFloat(acc.rcv_total).toFixed(2))}</TableCell> */}
                  <TableCell align="right">
                  <EditIcon style={{cursor:'pointer',color:'#2e482e',marginLeft:'2px'}} onClick={()=>{editJournalTran(acc,sl)}}></ EditIcon>
                  <span style={{width:'2px'}}> </span>
                  <RemoveCircleIcon style={{cursor:'pointer',color:'#582727',marginLeft:'2px'}} onClick={()=>{removeJournalTran(sl)}}></ RemoveCircleIcon>
                  </TableCell>
              
                  </TableRow>
                </>))
              }

              {
                rcvCart.length > 0 ? (<>
                <TableRow style={{background: 'rgb(249 243 243)',transition: '1s'}}>
                    <TableCell align="right" colSpan={5} style={{fontWeight:'bold'}}>Total  : </TableCell>
                    {/* <TableCell align="right" style={{fontWeight:'bold'}}> {format(parseFloat(_.sumBy(rcvCart,(tran)=>parseFloat(tran.rcv_amount))).toFixed(2))}</TableCell> */}
                    {/* <TableCell align="right" style={{fontWeight:'bold'}}> {format(parseFloat(_.sumBy(rcvCart,(tran)=>parseFloat(tran.tax_amount))).toFixed(2))}</TableCell> */}
                    <TableCell align="right" style={{fontWeight:'bold'}}> {format(parseFloat(_.sumBy(rcvCart,(tran)=>parseFloat(tran.rcv_total))).toFixed(2))}</TableCell>
                   
                    <TableCell align="right" style={{fontWeight:'bold'}}> {format(parseFloat(_.sumBy(rcvCart,(tran)=>parseFloat(tran.discount_amount))).toFixed(2))}</TableCell>

                    <TableCell align="right">----------</TableCell>
                 </TableRow>
                </>):''
                 
              }
             
         


     
        </TableBody>
      </Table>
    </TableContainer>


    <TableContainer  style={{float:'right'}}>
      <Table className={classes.table} size="small" aria-label="a dense table">
     
          <TableRow > 
             <TableCell style={{width: '325px'}}><TextareaAutosize 
              ref={narration_ref}
              
               onChange={(e)=>{narration_set(e.target.value)}} value={narration}  name="narration" style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Narration..." rowsMin={3} placeholder="Narration..." />
             </TableCell>

             <TableCell colSpan={2}></TableCell>

           
          
          </TableRow>
        
      </Table>
    </TableContainer>


        </Grid>



        </Grid>

      </Grid>


      <Grid item xs={12} sm={12} style={{marginBottom:'10px'}}>
              <input type="checkbox" 
              
               id="is_smg" checked={is_smg=='yes'?true:false} name="is_smg" 
                onChange={(e)=>is_smg_set(e.target.checked?'yes':'no')}
              />
              <label for="is_smg">Smg to Mobile?</label>
        </Grid>
  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        style={{color: 'white',
          backgroundColor: '#115f5f'}}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

     {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Receipt Entry List"}
      data={receipts}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '10px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
        position:'relative'  
  },
  plusLink:{
    margin: 0,
    padding: 0,
    marginTop: '-21px',
    fontSize: '29px',
    height: '21px',
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    color: '#3e8d54'
  }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);